import { Programa } from '../types/types';

export const VIVO_URL = 'https://streaming1.hostingmontevideo.com/8034/stream';
export const BASQUETBOL_URL = 'https://server-usa2.radioseninternet.net/9308/stream';
export const MALDONADO_URL = 'https://streaming1.hostingmontevideo.com/8038/stream';
export const CARNAVAL_URL = 'https://server-usa2.radioseninternet.net/8036/stream';

export const NOTICIAS: Programa = {
  title: 'Noticias',
  conducen: '',
  horario: '',
  description: '',
  slug: 'noticias',
  categoryId: 57,
};

export const SOCIEDAD_URBANA: Programa = {
  title: 'Sociedad Urbana',
  conducen: '',
  horario: '',
  description: '',
  slug: 'sociedad-urbana',
};

export const LA_CUCHARA: Programa = {
  title: 'La Cuchara',
  conducen: 'Fernando Tetes',
  horario: 'Lunes a Viernes de 16:00 a 17:00',
  description: '',
  slug: 'la-cuchara',
  categoryId: 50412,
};

export const NADA_QUE_PERDER: Programa = {
  title: 'Nada Que Perder',
  conducen: 'Lucas Labandera, Sofía Kortysz y Alejandra Couto',
  horario: 'Lunes a Viernes de 08:00 a 11:00',
  description:
    'Periodístico que aborda la realidad política, social y económica apelando a la pluralidad de voces. Con una entrevista central con actores políticos y sociales que marcan la agenda informativa diaria, analistas políticos y económicos, además de informes especiales junto con espacios de debate.',
  slug: 'nadaqueperder',
  categoryId: 31787,
};

export const JUSTICIA_INFINITA: Programa = {
  title: 'Justicia Infinita',
  conducen: 'Gonzalo Cammarota, Ximena Cedres, Junio Riverman, Ignacio Alcuri y Alejandro Dalto',
  horario: 'Lunes a Viernes de 10:00 a 14:00',
  description:
    'Gonazlo Cammarota celebra la vigésima temporada de un clásico del dial junto a un gran equipo que, prácticamente, ya juega de memoria. Junio Riverman con clases abiertas de periodismo deportivo, Nacho Alcuri exponiéndose sin cuidado a todo tipo de películas y series, Julio César Migues con su vocación por informar, la pareja del clima integrada por Charito y Raúl Falero, y el regreso de Ximena Cedrés al estudio, completan un equipo de lujo para transitar tres horas diarias de terapia auditiva.',
  slug: 'justicia-infinita',
  categoryId: 35119,
};

export const LO_MEJOR_DE_JUSTICIA_INFINITA: Programa = {
  title: 'Lo mejor de Justicia Infinita',
  conducen: 'Gonzalo Cammarota, Ximena Cedres, Junio Riverman, Ignacio Alcuri y Alejandro Dalto',
  horario: 'Lunes a Viernes de 11:00 a 12:00',
  description:
    'Gonazlo Cammarota celebra la vigésima temporada de un clásico del dial junto a un gran equipo que, prácticamente, ya juega de memoria. Junio Riverman con clases abiertas de periodismo deportivo, Nacho Alcuri exponiéndose sin cuidado a todo tipo de películas y series, Julio César Migues con su vocación por informar, la pareja del clima integrada por Charito y Raúl Falero, y el regreso de Ximena Cedrés al estudio, completan un equipo de lujo para transitar tres horas diarias de terapia auditiva.',
  slug: 'justicia-infinita',
  categoryId: 35119,
};

export const MENU_INFORMATIVO: Programa = {
  title: 'Menú Informativo',
  conducen: 'Alejandra Couto y Sherezade Errico',
  horario: 'Lunes a Viernes de 17:00 a 18:00',
  description:
    'Noticias, entrevistas y columnas con análisis e innovación, análisis político, social y económico, salud, turismo, espectáculos y seguridad ciudadana.',
  slug: 'menuinformativo',
  categoryId: 31790,
};

export const MEJOR_HABLAR: Programa = {
  title: 'Mejor Hablar',
  conducen: 'Martín Rodríguez y Mariana Abreu',
  horario: 'Lunes a Viernes de 08:00 a 10:00',
  description:
    'Mejor hablar es un periodístico que aborda diferentes realidades políticas, sociales y económicas con una mirada propia, equilibrada pero no indiferente, que apela a la pluralidad de voces, el rigor profesional, la autonomía de agenda, y la investigación como forma de profundizar en las distintas capas de la realidad.',
  slug: 'mejor-hablar',
  categoryId: 310,
};

export const TAPALO_CON_RADIO: Programa = {
  title: 'Tapalo con Radio',
  conducen: 'Pilar Roselló y Sebastián Moreira',
  horario: 'Lunes a Viernes de 13:00 a 15:00',
  description:
    'Un programa enfocado en al cultura y el arte, con especial atención a las discusiones relevantes del día a día y que no tienen su lugar en la agenda diaria. Se trata de explorar lo que escondemos debajo de la alfombra, tanto desde una óptica fantástica, como un lugar mágico que puede conectarnos con hisorias, pero también desde un lugar de mirar lo que barremos debajo de la alfombra, y las cosa de las que nos cuesta hablar.',
  slug: 'tapalo-con-radio',
  categoryId: 311,
};

export const EL_MENU_DEL_DIA: Programa = {
  title: 'El Menú del Día',
  conducen: 'Alejandra Couto y Sherezade Errico',
  horario: 'Lunes a Viernes de 12:00 a 13:00',
  description:
    'Somos el aderezo perfecto para acompañarte al mediodía. Un periodístico diferente, conducido por mujeres, donde cada una aportará un ingrediente fundamental para elaborar la receta perfecta: acompañarte en tus almuerzos con una pizca de frescura, variedad y un toque de humor. Te contamos todo lo que tenés que saber del acontecer diario, nuestros columnistas te dejarán pensando, y las entrevistas centrales serán la antesala del postre. Te esperamos, la mesa está servida.',
  slug: 'el-menu-del-dia',
  categoryId: 312,
};

export const TODO_POR_LA_MISMA_PLATA: Programa = {
  title: 'Todo por la Misma Plata',
  conducen:
    'Andrés Reyes, Santiago Díaz, Belén Zorrilla, con la presencia especial de Lubo Adusto Freire',
  horario: 'Lunes a Viernes de 14:00 a 16:00',
  description:
    'Magazine ágil y dinámico. Actualidad, entretenimiento, humor y vida cotidiana, con un abordaje de diferentes temas del universo político cultural de un modo poco tradicional, con la audiencia como protagonista central.',
  slug: 'todo-por-la-misma-plata',
  categoryId: 33,
};

export const LO_MEJOR_DE_TODO_POR_LA_MISMA_PLATA: Programa = {
  title: 'Lo mejor de Todo por la Misma Plata',
  conducen:
    'Andrés Reyes, Santiago Díaz, Belén Zorrilla, con la presencia especial de Lubo Adusto Freire',
  horario: 'Lunes a Viernes de 12:00 a 13:00',
  description:
    'Magazine ágil y dinámico. Actualidad, entretenimiento, humor y vida cotidiana, con un abordaje de diferentes temas del universo político cultural de un modo poco tradicional, con la audiencia como protagonista central.',
  slug: 'todo-por-la-misma-plata',
  categoryId: 33,
};

export const POR_DECIR_ALGO: Programa = {
  title: 'Por Decir Algo',
  conducen: 'Felipe Fernández, Facundo Castro y Sebastián Moreira',
  horario: 'Lunes a Viernes de 13:30 a 15:00',
  description:
    'Es un programa esencialmente polideportivo, con dedicación al fútbol desde un lugar analítico, y un interés especial en la cobertura de las carreras de los deportistas y selecciones uruguayas de todas las disciplinas. Se trata de una propuesta diferente, donde el deporte es el centro, pero también una excusa para contar historias humanas, políticas y sociales.',
  slug: 'por-decir-algo',
  categoryId: 32,
};

export const HABLANDO_AL_SUR: Programa = {
  title: 'Hablando al Sur | José Mujica',
  conducen: '',
  horario: '',
  slug: 'hablando-al-sur-jose-mujica',
  categoryId: 14,
};

export const ULTIMO_CUARTO: Programa = {
  title: 'Último Cuarto',
  conducen: 'Gustavo Seijas, Santi Rodríguez, Juan Pablo Taibo, Martin Frydman y todo el equipo',
  horario: 'Lunes a Sábados de 23:00 a 01:00',
  description:
    'De la mano de Último Cuarto, M24 tiene la primera transmisión de Básquet en FM en Uruguay. Previa, relato, comentarios y el básquet después del básquet. Conducen: Gustavo Seijas, Santiago Rodríguez, Juan Pablo Taibo.',
  slug: 'ultimo-cuarto',
  categoryId: 267,
};

export const TIEMPO_AGRARIO: Programa = {
  title: 'Tiempo Agrario',
  conducen: 'Julio Nauar y Facundo Berterreche',
  horario: 'De Lunes a Viernes de 06:00 a 07:00',
  slug: 'tiempo-agrario',
  categoryId: 18,
};

export const ALQUIMIA: Programa = {
  title: 'Alquimia',
  conducen: 'Felipe Castro',
  horario: 'Lunes a Viernes de 18:30 a 19:00',
  description:
    'Alquimia está hecho en Maldonado, y toda conversación es una lección de Alquimia. Maldonado es un lugar donde los generosos anfitriones se mezclan todo el año y mucho más durante 15 o 20 días, con miles de inmigrantes de otros departamentos y otros países, donde se trabaja la ciudad y el campo, donde se cuida la playa y la tierra, donde se vive, se sufre, se aprende y se ama. De esa mezcla nace cultura y de esa cultura nace Alquimia para mostrar puntos de vista y arte, el arte que nace de esa mezclada cultura.',
  slug: 'alquimia',
  categoryId: 34,
};

export const EL_VIAJE: Programa = {
  title: 'El Viaje',
  conducen: '',
  horario: 'Lunes a Viernes 00:00 a 05:00',
  slug: 'el-viaje',
};

export const MUSICA_EN_CLARIDAD: Programa = {
  title: 'Música en Claridad',
  conducen: '',
  horario: 'Lunes a Viernes de 05:00 a 06:00',
  slug: 'musica-en-claridad',
};

export const INFO24: Programa = {
  title: 'Info 24',
  conducen: 'Álvaro Pan Cruz',
  horario: 'Lunes a Viernes de 07:00 a 08:00',
  description:
    'Apertura informativa de la jornada con una hora de noticias, entrevistas y análisis político, económico y social de Uruguay y del mundo.',
  slug: 'info24',
  categoryId: 7,
};

export const EN_ORBITA: Programa = {
  title: 'En Órbita',
  conducen: 'Federico Gyurkovits y Alejandra Patrone',
  horario: 'Lunes a Viernes de 19:00 a 20:00',
  description:
    'En Orbita es un programa de actualidad internacional producido por la agencia internacional de noticias y radio Sputnik  (mundo.sputniknews.com) desde sus estudios en Montevideo. Una hora llena de información y análisis, que comienza con el noticiero internacional en la voz de protagonistas y analistas desde el lugar donde nace la noticia, con la coordinación de Mathías Dávalos.',
  slug: 'en-orbita',
  categoryId: 19,
};

export const PUENTES: Programa = {
  title: 'Puentes',
  conducen: '',
  horario: '',
  slug: 'puentes',
};

export const A_DOS_ORILLAS: Programa = {
  title: 'A dos orillas',
  conducen: 'Víctor Hugo Morales y Álvaro Pan Cruz',
  horario: 'Martes y Jueves de 13:00 a 13:30',
  description: 'Victor Hugo y Álvaro Pan conversan sobre la realidad política rioplatense.',
  slug: 'a-dos-orillas',
  categoryId: 71,
};

export const RADIO_EN_VIVO: Programa = {
  title: 'Música M24',
  conducen: '',
  horario: '',
  slug: 'radio-en-vivo',
};

export const RADIO_MALDONADO: Programa = {
  title: 'Radio en Vivo - Maldonado',
  conducen: '',
  horario: '',
  slug: 'maldonado',
};

export const BASQUETBOL: Programa = {
  title: 'Básquetbol',
  conducen: '',
  horario: '',
  slug: 'basquetbol',
};

export const CARNAVAL: Programa = {
  title: 'Carnaval',
  conducen: '',
  horario: '',
  slug: 'carnaval',
};

export const CARNAVAL_DEL_FUTURO: Programa = {
  title: 'Carnaval del futuro',
  conducen: '',
  horario: '',
  slug: 'carnaval-del-futuro',
};

export const COLUMNISTAS: Programa = {
  title: 'Columnistas',
  conducen: '',
  horario: '',
  slug: 'columnistas',
  categoryId: 63,
};

export const INTERNACIONALES: Programa = {
  title: 'Internacionales',
  conducen: '',
  horario: '',
  slug: 'internacionales',
  categoryId: 66,
};

export const EMPRESARIALES: Programa = {
  title: 'Empresariales',
  conducen: '',
  horario: '',
  slug: 'empresariales',
  categoryId: 36584,
};

export const EXPRESSO_222: Programa = {
  title: 'Expresso 222',
  conducen: '',
  horario: '',
  slug: 'expresso-222',
};

export const ESCENARIA: Programa = {
  title: 'Escenaria',
  conducen: '',
  horario: '',
  slug: 'escenaria',
};

export const DESTINO_RUSIA: Programa = {
  title: 'Destino Rusia',
  conducen: '',
  horario: '',
  description:
    'Los sábados, Karen presenta Destino Rusia, una sección dedicada al intercambio entre Rusia y América Latina, con protagonistas que hablan de sus experiencias en uno y otro lado del mundo. El equipo se completa con la producción de Natalia Verdún, los operadores Alejandro Correa y Leonardo Martínez y la dirección de Patrica Lee Wynne.',
  slug: 'destino-rusia',
  categoryId: 21,
};

export const ENTRE_MURGAS_Y_CANDOMBES: Programa = {
  title: 'Entre murgas y candombes',
  conducen: '',
  horario: '',
  slug: 'entre-murgas-y-candombes',
};

export const LA_RAMBLA: Programa = {
  title: 'La Rambla',
  conducen: '',
  horario: '',
  slug: 'la-rambla',
};

export const ESTACION_PIAZZOLLA: Programa = {
  title: 'Estación Piazolla 100 años / Víctor Hugo Morales',
  conducen: '',
  horario: '',
  slug: 'estacion-piazzolla',
};

export const GPS_INTERNACIONAL: Programa = {
  title: 'GPS Internacional',
  conducen: 'Fabián Cardozo . Producción: Gabriela Casullo y Santiago Caetano',
  horario: 'Martes, Jueves y Viernes de 20 a 21 horas.',
  description:
    'GPS internacional de mundo.sputniknews.com El programa de radio donde participan expertos y protagonistas del quehacer latinoamericano para discutir los principales temas políticos de la región.',
  slug: 'gps-internacional',
  categoryId: 20,
};

export const UNIVERSOS_PARALELOS: Programa = {
  title: 'Universos Paralelos',
  conducen: 'Mariana Fernández y Diego Cortés',
  horario: 'Miércoles y Viernes 17 hs',
  description: '',
  slug: 'universos-paralelos',
  categoryId: 35,
};

export const INFORME_GRANJERO: Programa = {
  title: 'Informe Granjero',
  conducen: 'Daniel Tachini e Ignacio Cirio',
  horario: 'Sabados 06:00 a 08:00 hs',
  description:
    'Noticias, información técnica, económica, comercial y política vinculada directamente al sector agropecuario y a la producción de alimentos.',
  slug: 'informe-granjero',
  categoryId: 14249,
};

export const COLADOS_AL_CAMION: Programa = {
  title: 'Colados al Camión',
  conducen: '',
  horario: '',
  description: '',
  slug: 'colados-al-camion',
  categoryId: 266,
};

export const ESPACIO_AFCASMU: Programa = {
  title: 'Espacio Afcasmu',
  conducen: '',
  horario: '',
  description: '',
  slug: 'espacio-afcasmu',
};

export const OTRAS_MANERAS: Programa = {
  title: 'Otras Maneras',
  conducen: 'Ana Prada y Mariano Molina',
  horario: 'Sábados de 10 a 12 hs',
  description:
    'Un programa semanal que propone recorrer diversas músicas y volver a vivir la experiencia de escuchar un disco completo. En cada emisión analizamos una producción discográfica de Uruguay o Argentina y su contexto político, social y cultural dialogando con artistas, técnicos, periodistas o personalidades destacadas de la cultura. Además, el ciclo cuenta con columnistas especializados de ambos países.',
  slug: 'otras-maneras',
  categoryId: 14252,
};

export const CAMINANTE: Programa = {
  title: 'Caminante',
  conducen: '',
  horario: '',
  slug: 'caminante',
};

export const ANTES_QUE_ME_OLVIDE: Programa = {
  title: 'Antes que me olvide',
  conducen: '',
  horario: '',
  slug: 'antes-que-me-olvide',
};

export const MEDIODIA_CON_RASTAS: Programa = {
  title: 'Mediodía con rastas',
  conducen: '',
  horario: '',
  slug: 'mediodia-con-rastas',
};

export const BR_97_9: Programa = {
  title: 'BR 97-9',
  conducen: '',
  horario: '',
  slug: 'br-97-9',
};

export const TARDE_RASTA: Programa = {
  title: 'Tarde Rasta',
  conducen: '',
  horario: '',
  slug: 'tarde-rasta',
};

export const MUSICA_QUE_NOS_MUEVE: Programa = {
  title: 'Música que nos Mueve',
  conducen: '',
  horario: '',
  slug: 'musica-que-nos-mueve',
};

export const MIX_24: Programa = {
  title: 'Mix 24',
  conducen: '',
  horario: '',
  slug: 'mix-24',
};

////////////

export const POLITICA: Programa = {
  title: 'Política',
  conducen: '',
  horario: '',
  slug: 'politica',
  categoryId: 98317,
};

export const ECONOMIA: Programa = {
  title: 'Economía',
  conducen: '',
  horario: '',
  slug: 'economia',
  categoryId: 98323,
};

export const SOCIEDAD: Programa = {
  title: 'Sociedad',
  conducen: '',
  horario: '',
  slug: 'sociedad',
  categoryId: 98320,
};

export const MUNDO: Programa = {
  title: 'Internacionales',
  conducen: '',
  horario: '',
  slug: 'mundo',
  categoryId: 98328,
};

export const FUTBOL: Programa = {
  title: 'Fútbol',
  conducen: '',
  horario: '',
  slug: 'futbol',
  categoryId: 98325,
};

interface ProgramasMap {
  [name: string]: Programa;
}

export const PROGRAMAS_MAP: ProgramasMap = {
  [EL_VIAJE.slug]: EL_VIAJE,
  [MUSICA_EN_CLARIDAD.slug]: MUSICA_EN_CLARIDAD,
  [TIEMPO_AGRARIO.slug]: TIEMPO_AGRARIO,
  [INFO24.slug]: INFO24,
  [MEJOR_HABLAR.slug]: MEJOR_HABLAR,
  [TAPALO_CON_RADIO.slug]: TAPALO_CON_RADIO,
  [EL_MENU_DEL_DIA.slug]: EL_MENU_DEL_DIA,
  [HABLANDO_AL_SUR.slug]: HABLANDO_AL_SUR,
  [POR_DECIR_ALGO.slug]: POR_DECIR_ALGO,
  [TODO_POR_LA_MISMA_PLATA.slug]: TODO_POR_LA_MISMA_PLATA,
  [ALQUIMIA.slug]: ALQUIMIA,
  [EN_ORBITA.slug]: EN_ORBITA,
  [PUENTES.slug]: PUENTES,
  [ULTIMO_CUARTO.slug]: ULTIMO_CUARTO,
  [A_DOS_ORILLAS.slug]: A_DOS_ORILLAS,
  [RADIO_EN_VIVO.slug]: RADIO_EN_VIVO,
  [COLUMNISTAS.slug]: COLUMNISTAS,
  [INTERNACIONALES.slug]: INTERNACIONALES,
  [EXPRESSO_222.slug]: EXPRESSO_222,
  [ESCENARIA.slug]: ESCENARIA,
  [DESTINO_RUSIA.slug]: DESTINO_RUSIA,
  [ENTRE_MURGAS_Y_CANDOMBES.slug]: ENTRE_MURGAS_Y_CANDOMBES,
  [LA_RAMBLA.slug]: LA_RAMBLA,
  [ESTACION_PIAZZOLLA.slug]: ESTACION_PIAZZOLLA,
  [BASQUETBOL.slug]: BASQUETBOL,
  [UNIVERSOS_PARALELOS.slug]: UNIVERSOS_PARALELOS,
  [GPS_INTERNACIONAL.slug]: GPS_INTERNACIONAL,
  [INFORME_GRANJERO.slug]: INFORME_GRANJERO,
  [COLADOS_AL_CAMION.slug]: COLADOS_AL_CAMION,
  [ESPACIO_AFCASMU.slug]: ESPACIO_AFCASMU,
  [OTRAS_MANERAS.slug]: OTRAS_MANERAS,
  [NOTICIAS.slug]: NOTICIAS,
  [CARNAVAL.slug]: CARNAVAL,
  [NADA_QUE_PERDER.slug]: NADA_QUE_PERDER,
  [JUSTICIA_INFINITA.slug]: JUSTICIA_INFINITA,
  [MENU_INFORMATIVO.slug]: MENU_INFORMATIVO,
  [LA_CUCHARA.slug]: LA_CUCHARA,
  [SOCIEDAD_URBANA.slug]: SOCIEDAD_URBANA,
  [CARNAVAL_DEL_FUTURO.slug]: CARNAVAL_DEL_FUTURO,
  [CAMINANTE.slug]: CAMINANTE,
  [ANTES_QUE_ME_OLVIDE.slug]: ANTES_QUE_ME_OLVIDE,
  [MEDIODIA_CON_RASTAS.slug]: MEDIODIA_CON_RASTAS,
  [BR_97_9.slug]: BR_97_9,
  [TARDE_RASTA.slug]: TARDE_RASTA,
  [MUSICA_QUE_NOS_MUEVE.slug]: MUSICA_QUE_NOS_MUEVE,
  [MIX_24.slug]: MIX_24,
};

type ProgramacionType = {
  [horario: string]: string;
}[];

const programacionLunesAViernes = {
  '00:00-03:59': CARNAVAL_DEL_FUTURO.slug,
  '04:00-04:59': ENTRE_MURGAS_Y_CANDOMBES.slug,
  '05:00-07:59': MUSICA_EN_CLARIDAD.slug,
  '08:00-08:59': INFO24.slug,
  '09:00-10:59': NADA_QUE_PERDER.slug,
  '11:00-13:59': JUSTICIA_INFINITA.slug,
  '14:00-15:59': TODO_POR_LA_MISMA_PLATA.slug,
  '16:00-16:59': LA_CUCHARA.slug,
  '17:00-17:59': TARDE_RASTA.slug,
  '18:00-19:59': PUENTES.slug,
  '20:00-23:59': CARNAVAL_DEL_FUTURO.slug
};

const programacionSabados = {
  '00:00-03:59': CARNAVAL_DEL_FUTURO.slug,
  '04:00-04:59': ENTRE_MURGAS_Y_CANDOMBES.slug,
  '05:00-05:59': MUSICA_EN_CLARIDAD.slug,
  '06:00-07:59': INFORME_GRANJERO.slug,
  '08:00-08:59': CAMINANTE.slug,
  '09:00-11:59': RADIO_EN_VIVO.slug,
  '12:00-12:59': MEDIODIA_CON_RASTAS.slug,
  '13:00-13:29': MIX_24.slug,
  '13:30-19:59': LA_RAMBLA.slug,
  '20:00-23:59': CARNAVAL_DEL_FUTURO.slug,
};

const programacionDomingo = {
  '00:00-01:29': CARNAVAL_DEL_FUTURO.slug,
  '01:30-04:59': EL_VIAJE.slug,
  '05:00-07:59': MUSICA_EN_CLARIDAD.slug,
  '08:00-09:59': RADIO_EN_VIVO.slug,
  '10:00-10:59': BR_97_9.slug,
  '11:00-11:59': RADIO_EN_VIVO.slug,
  '12:00-12:59': MEDIODIA_CON_RASTAS.slug,
  '13:00-13:29': MIX_24.slug,
  '13:30-19:59': LA_RAMBLA.slug,
  '20:00-23:59': CARNAVAL_DEL_FUTURO.slug,
};

export const PROGRAMACION: ProgramacionType = [
  // Domingo
  programacionDomingo,
  // Lunes
  programacionLunesAViernes,
  // Martes
  programacionLunesAViernes,
  // Miercoles
  programacionLunesAViernes,
  // Jueves
  programacionLunesAViernes,
  // Viernes
  programacionLunesAViernes,
  // Sábado
  programacionSabados,
];
